














































































































































































import Vue from 'vue';
import APISTATE from '@/enums/APISTATE';
import { mapActions, mapGetters } from 'vuex';
import { ClassSubjectDto } from '@/models/planning/ClassSubjectDto';
import { SchoolTermDto } from '@/models/planning/SchoolTermDto';


export default Vue.extend({
  name: 'schoolsubjects-view',
  data: () => ({
    filterDepartments: null,
    filterSchoolTerms: null,
    filterSchoolSubjects: null,
  }),
  beforeMount() {
    this.resetFilters();
  },
  async mounted() {
    this.updatePageTitle('Fagtilbud');
    await Promise.all([
      this.loadDepartments(),
      this.loadSchoolTerms(),
      this.loadSchoolSubjects()
    ]);
    this.applyGlobalSchoolTermSelection();
    if (this.filterSchoolTerms) {
      await this.loadClassSubjects();
    }
  },
  methods: {
    ...mapActions(['updatePageTitle']),
    ...mapActions('departments', ['loadDepartments']),
    ...mapActions('schoolTermStore', ['loadSchoolTerms']),
    ...mapActions('schoolSubjectsStore', ['loadSchoolSubjects']),
    ...mapActions('classSubjectStore', ['loadClassSubjects', 'setFilterProperty', 'clearFilterProperty', 'resetFilters']),
    onRowClick(row: ClassSubjectDto) {
      this.$router.push(`/classSubject/${row.id}`);
    },
    applyGlobalSchoolTermSelection() {
      const globalSchoolTerm = this.schoolTerms.find((q:SchoolTermDto) => q.id === this.globalSchoolTermSelection);
      if (globalSchoolTerm) {
        this.setFilterProperty({schoolTerms: globalSchoolTerm.id});
        this.filterSchoolTerms = globalSchoolTerm;
      }
    },
    tableRowClass(row/*, index*/) {
      let rowClasses = [];
      if (row.isDeactivated) {
        rowClasses.push('deactivated');
      }
      return rowClasses.join(' ');
    }

  },
  computed: {
    ...mapGetters(['globalSchoolTermSelection']),
    ...mapGetters('departments', {departments: 'departments', isLoadingDepartments: 'isLoading'}),
    ...mapGetters('schoolTermStore', ['schoolTerms', 'schoolTermApiState']),
    ...mapGetters('schoolSubjectsStore', {schoolSubjects: 'schoolSubjects', isLoadingSchoolSubjects: 'isLoading'}),
    ...mapGetters('classSubjectStore', ['classSubjects', 'classSubjectApiState']),
    isLoading(): Boolean {
      return this.classSubjectApiState === APISTATE.LOADING;
    },
    isLoadingSchoolTerms(): Boolean {
      return this.schoolTermApiState === APISTATE.LOADING;
    }

  }

})
